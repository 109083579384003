
import { Button, Col, Form, Row, Space, Spin, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CategoryModel } from '../../models/CategoryModel'
import { CategoryService } from '../../services/CategoryService'
import { Color } from '../../styles/Color'
import { MenuType } from '../../types/MenuType'
import { NavigationType } from '../../types/NavigationType'
import { 분류1Type } from '../../types/분류1Type'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'
import { InputForm } from '../../components/form/InputForm'
import TextArea from 'antd/es/input/TextArea'
import LocalStorageUtility from '../../utils/LocalStorageUtility'
import { isEmpty } from 'lodash-es'
import { 상품류확인Modal } from '../../components/modal/상품류확인Modal'

export const 상표등록: FunctionComponent = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const { state } = location

    const [분류1, set분류1] = useState<분류1Type>()
    const [분류2, set분류2] = useState<string>()
    const [분류3s, set분류3s] = useState<Array<string>>([])
    const [isLoading, setLoading] = useState<boolean>(false)
    const [open상품류확인Modal, setOpen상품류확인Modal] = useState<boolean>(false)
    const [category, setCategory] = useState<CategoryModel.ITrademarkCategoryModel>()
    const [flattenCategories, setFlattenCategories] = useState<Array<CategoryModel.ITrademarkCategoryFlattenModel>>([])

    const [form] = Form.useForm()

    const getCategories = async () => {
        setLoading(true)
        const response = await CategoryService.search()
        if (response.status === 200) {
            setCategory(response.data)
        }
        setLoading(false)
    }

    const getFlattenCategories = async () => {
        setLoading(true)
        const response = await CategoryService.flatten()
        if (response.status === 200) {
            setFlattenCategories(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        const userData = LocalStorageUtility.get('user')
        if (!userData) {
            alert('로그인 후 진행해주세요.')
            window.location.href = '/login'
        }

        if(state) {
            form.setFieldValue('name', state.keyword)
        }

        getCategories()
        getFlattenCategories()
    }, [])

    return (
        <Container type={NavigationType.White} menu={MenuType.상표등록}>
            <Spin spinning={isLoading}>
                <Row
                    gutter={[0, 12]}
                    className='create-trademark-container'
                >
                    <Col span={24}>
                        <LayoutContainer style={{ gap: 20, }}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 28,
                                    }}
                                >
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 28,
                                            color: Color.Blue50,
                                        }}
                                    >
                                        {`어디에 사용`}
                                    </Typography.Text>
                                    {`되는 `}
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 28,
                                            color: Color.Blue50,
                                        }}
                                    >
                                        {`상표`}
                                    </Typography.Text>
                                    {`인가요?`}
                                </Typography.Text>
                            </Col>
                        </LayoutContainer>
                    </Col>
                    <Col span={24}>
                        <LayoutContainer style={{ gap: 0, marginTop: 20, }}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 18,
                                    }}
                                >
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 18,
                                        }}
                                    >
                                        {`한글/영어 모두 입력`}
                                    </Typography.Text>
                                    {`해야`}
                                </Typography.Text>
                            </Col>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 18,
                                    }}
                                >
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 18,
                                        }}
                                    >
                                        {`정확한 등록 가능성`}
                                    </Typography.Text>
                                    {`을 확인할 수 있어요!`}
                                </Typography.Text>
                            </Col>
                        </LayoutContainer>
                    </Col>
                    <Col span={24}>
                        <Form form={form}>
                            <LayoutContainer style={{ gap: 20, }}>
                                <Col span={24} style={{ marginTop: 20, }}>
                                    <Row gutter={[0, 20]}>
                                        <Col span={24}>
                                            <Typography.Text
                                                strong
                                                style={{
                                                    fontSize: 20,
                                                }}
                                            >
                                                {`상표의 명칭 (한글, 한글 + 영어)`}
                                            </Typography.Text>
                                        </Col>
                                        <InputForm
                                            name='name'
                                            placeholder='상표명을 한글 또는 한글 + 영어로 입력하세요'
                                            style={{
                                                height: 50,
                                                borderRadius: 30,
                                                fontSize: 16,
                                                paddingLeft: 30,
                                            }}
                                            layout={{ lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                                        />
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[0, 20]}>
                                        <Col span={24}>
                                            <Typography.Text
                                                strong
                                                style={{
                                                    fontSize: 20,
                                                }}
                                            >
                                                {`상표의 명칭 (영어)`}
                                            </Typography.Text>
                                        </Col>
                                        <InputForm
                                            name='alphabet'
                                            placeholder='상표명을 영어로 입력하세요'
                                            style={{
                                                height: 50,
                                                borderRadius: 30,
                                                fontSize: 16,
                                                paddingLeft: 30,
                                            }}
                                            // rules={[
                                            //     { pattern: /[a-z]/, message: '상표의 명칭은 영어로만 입력해 주세요' },
                                            // ]}
                                            layout={{ lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                                        />
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[0, 20]}>
                                        <Col span={24}>
                                            <Space direction='vertical'>
                                                <Typography.Text
                                                    strong
                                                    style={{
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    {`[필수] 상품/서비스 정보`}
                                                </Typography.Text>
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 16,
                                                        color: Color.Gray60 
                                                    }}
                                                >
                                                    {`상표가 사용될 상품이나 서비스에 대해 자세하게 설명해주세요.`}
                                                </Typography.Text>
                                            </Space>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="description"
                                                // rules={[{ required: true, message: "상표 정보를 입력하세요" }]}
                                            >
                                                <TextArea
                                                    allowClear
                                                    placeholder={'ex) 뷰티 서비스를 준비중에 있습니다. \n앱/웹 서비스 출시 예정이고, 추후 유통 판매까지\n생각하고 있습니다.'}
                                                    maxLength={400}
                                                    autoSize={{
                                                        minRows: 5
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </LayoutContainer>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <LayoutContainer style={{ gap: 20, }}>
                            <Col span={24} style={{ marginTop: 20, }}>
                                <Typography.Text
                                    strong
                                    style={{
                                        fontSize: 20,
                                    }}
                                >
                                    분류 1.
                                </Typography.Text>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[30, 0]}>
                                    <Col span={12}>
                                        <Button
                                            block
                                            className={`large ${분류1 === '제품' ? 'activated' : ''}`}
                                            onClick={() => {
                                                set분류1(분류1Type.제품)
                                            }}
                                        >
                                            <Space direction='vertical'>
                                                <Typography.Text
                                                    strong
                                                    style={{
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    제품
                                                </Typography.Text>
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 18,
                                                    }}
                                                >
                                                    제품, 브랜드
                                                </Typography.Text>
                                            </Space>
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button
                                            block
                                            className={`large ${분류1 === '서비스' ? 'activated' : ''}`}
                                            onClick={() => {
                                                set분류1(분류1Type.서비스)
                                            }}
                                        >
                                            <Space direction='vertical'>
                                                <Typography.Text
                                                    strong
                                                    style={{
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    서비스
                                                </Typography.Text>
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 18,
                                                    }}
                                                >
                                                    상호, 서비스
                                                </Typography.Text>
                                            </Space>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </LayoutContainer>
                    </Col>
                    {category && 분류1 && (
                        <Col span={24} style={{ marginTop: 20 }}>
                            <LayoutContainer style={{ gap: 20, }}>
                                <Col span={24} style={{ marginTop: 20, }}>
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 20,
                                        }}
                                    >
                                        분류 2.
                                    </Typography.Text>
                                </Col>
                                <Col span={24}>
                                    <Space size={20} wrap>
                                        {category && 분류1 && (
                                            Object.keys(category[분류1])
                                                .map((c) => {
                                                    const isActivated = 분류2 === c
                                                    let count = Object.keys(category[분류1][c])
                                                        ?.flatMap(i => Object.keys(category[분류1][c][i]))
                                                        ?.filter(i => 분류3s.includes(i))
                                                        ?.length ?? 0

                                                    return (
                                                        <Button
                                                            key={c}
                                                            className={`${isActivated ? 'activated' : ''}`}
                                                            onClick={() => {
                                                                set분류2(c)
                                                            }}
                                                        >
                                                            <Typography.Text
                                                                style={{
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                {`${c}${count > 0 ? `(${count})` : ""}`}
                                                            </Typography.Text>
                                                        </Button>
                                                    )
                                                }))}
                                    </Space>
                                </Col>
                            </LayoutContainer>
                        </Col>
                    )}
                    <Col span={24} style={{ marginTop: 40 }}>
                        <LayoutContainer style={{ gap: 20, }}>
                            <Col span={24} style={{}}>
                                {category && 분류1 && 분류2 && category[분류1][분류2] && (
                                    Object.keys(category[분류1][분류2])
                                        ?.map((c) => {
                                            return (
                                                <Row gutter={[0, 20]} key={c} style={{ marginBottom: 40, }}>
                                                    <Col span={24}>
                                                        <Typography.Text
                                                            strong
                                                            style={{
                                                                fontSize: 18,
                                                            }}
                                                        >
                                                            {c}
                                                        </Typography.Text>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Space size={20} wrap>
                                                            {Object.keys(category[분류1][분류2][c])
                                                                ?.map((item) => {
                                                                    const isActivated = 분류3s.includes(item)
                                                                    return (
                                                                        <Button
                                                                            key={item}
                                                                            className={`${isActivated ? 'activated' : ''}`}
                                                                            style={{
                                                                                borderRadius: 30,
                                                                            }}
                                                                            onClick={() => {
                                                                                if (분류3s.includes(item)) {
                                                                                    set분류3s([...분류3s.filter(i => i !== item)])
                                                                                } else {
                                                                                    set분류3s([...분류3s, item])
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Typography.Text
                                                                                style={{
                                                                                    fontSize: 16,
                                                                                }}
                                                                            >
                                                                                {item}
                                                                            </Typography.Text>
                                                                        </Button>
                                                                    )
                                                                })}
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            )
                                        }))}
                            </Col>
                        </LayoutContainer>
                    </Col>
                    <Col span={24} style={{ marginTop: 20 }}>
                        <LayoutContainer style={{ justifyContent: 'center' }}>
                            <Col span={12}>
                                <Button
                                    block
                                    style={{ textAlign: 'center' }}
                                    onClick={() => {
                                        form.validateFields()
                                            .then((values) => {
                                                if(isEmpty(values.name) && isEmpty(values.alphabet)) {
                                                    alert('상표의 명칭을 하나 이상 입력하세요(한글 또는 영어)')
                                                    return
                                                }

                                                if(isEmpty(values.description)) {
                                                    alert('상품/서비스 정보를 입력하세요')
                                                    return
                                                }

                                                if(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(values.alphabet)) {
                                                    alert('상표의 명칭(영어)은 영어만 입력하세요.')
                                                    return
                                                }

                                                if(분류3s.length <= 0) {
                                                    alert('상품류를 선택하세요.')
                                                    return
                                                }

                                                setOpen상품류확인Modal(true)
                                            })
                                            .catch((e) => {
                                                return
                                            })
                                    }}
                                >
                                    검색 결과 보러 가기
                                </Button>
                            </Col>
                        </LayoutContainer>
                    </Col>
                </Row>
            </Spin>
            <상품류확인Modal
                open={open상품류확인Modal}
                categories={분류3s ?? []}
                onConfirm={() => {
                    setOpen상품류확인Modal(false)
                    navigate('/trademark-able', {
                        state: {
                            ...form.getFieldsValue(),    
                            categories: flattenCategories.filter(f => 분류3s.includes(f.c4))
                        }
                    })
                }}
                onClosed={() => {
                    setOpen상품류확인Modal(false)
                }}
            />
        </Container>
    )
}
export namespace 출원방법타입 {
    export enum 출원방법 {
        직접출원 = 1,
        바로출원 = 2,
        간편출원 = 3,
        심화출원 = 4,
    }

    export const displayName = (type: 출원방법) => {
        switch (type) {
            case 출원방법.직접출원:
                return '직접 출원'
            case 출원방법.바로출원:
                return '바로 출원'
            case 출원방법.간편출원:
                return '간편 출원'
            default:
                return '심화 출원'
        }
    }
}
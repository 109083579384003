import { Button, Col, Row, Space, Spin, Table, Tag, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import CountUp from 'react-countup'
import { useLocation, useNavigate } from 'react-router-dom'
import { CategoryModel } from '../../models/CategoryModel'
import { CheckModel } from '../../models/CheckModel'
import { CheckService } from '../../services/CheckService'
import { Color } from '../../styles/Color'
import '../../styles/circle.scss'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'
import { 전문가Modal } from '../../components/modal/전문가Modal'
import { 추가상품류Modal } from '../../components/modal/추가상품류Modal'
import { 출원방법선택Modal } from '../../components/modal/출원방법선택Modal'
import { 출원방법타입 } from '../../types/출원방법타입'
import LocalStorageUtility from '../../utils/LocalStorageUtility'
import { NavigationType } from '../../types/NavigationType'
import { LoadingOutlined } from '@ant-design/icons'
import DateUtility from '../../utils/DateUtility'

export const 출원가능성: FunctionComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { state } = location
    if (!state) {
        window.location.href = '/create/trademarks'
    }

    const { name, alphabet, description, categories } = state as {
        name: string
        alphabet?: string
        description: string
        categories: Array<CategoryModel.ITrademarkCategoryFlattenModel>
    }

    const [showModal, setShowModal] = useState(false)
    const [show추가상품류Modal, setShow추가상품류Modal] = useState(false)
    const [show출원방법Modal, setShow출원방법Modal] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [ratio, setRatio] = useState(0)
    const [count, setCount] = useState(0)
    const [category, setCategory] = useState<CategoryModel.ITrademarkCategoryFlattenModel>(
        // {
        //     c1: "제품",
        //     c2: "패션의류/잡화",
        //     c3: "의류",
        //     c4: "의류",
        //     codes: ['25', '35', '18', '03', '26', '09', '14', '24', '28', '16'],
        // }
    )
    const [secondaryCodes, setSecondaryCodes] = useState<Array<string>>()
    const [similar, setSimilar] = useState<Array<CheckModel.ITrademarkCheckSimilarModel>>([])

    const ratioColor = () => {
        return Math.round(ratio) > 50 ? Color.Blue50 : Color.Orange60
    }

    const 등록가능성문자열 = () => {
        switch (true) {
            case (ratio >= 70):
                return '높아요!'
            case (ratio > 50):
                return '보통이에요!'
            case (ratio > 30):
                return '낮아요!'
            default:
                return '매우낮아요!'
        }
    }

    const 버튼문자열 = () => {
        switch (true) {
            case (ratio >= 70):
                return '출원하러 가기'
            default:
                return '전문가 매칭하기'
        }
    }

    const 등록가능성참고문자열 = () => {
        let title = '등록 가능성이 매우낮으면\n전문가 매칭이 필요해요!'
        let description = '본 상표는 동일한 상표가 있어 등록 가능성이 매우낮음입니다.'

        switch (true) {
            case (ratio >= 70):
                title = '등록 가능성이 높으면\n스스로 출원이 가능해요!'
                description = '본 상표는 동일한 상표가 없어 등록 가능성이 높습니다.'
                break
            case (ratio > 50):
                title = '등록 가능성이 보통이면\n전문가 매칭이 필요해요!'
                description = '본 상표는 비슷한 상표가 있어 등록 가능성이 보통입니다.'
                break
            case (ratio > 30):
                title = '등록 가능성이 낮으면\n전문가 매칭이 필요해요!'
                description = '본 상표는 비슷한 상표가 있어 등록 가능성이 낮음입니다.'
                break
        }

        return (
            <Space direction='vertical'>
                <Typography.Text
                    strong
                    style={{
                        fontSize: 24,
                    }}
                >
                    {title}
                </Typography.Text>
                <Typography.Text
                    style={{
                        fontSize: 20,
                    }}
                >
                    {description}
                </Typography.Text>
            </Space>
        )
    }

    const getRatio = async (classification?: CategoryModel.ITrademarkCategoryClassificationModel) => {
        setLoading(true)
        const groups = categories
            .map((c) => { return { category: c.c4, primary: c.codes[0] } })

        const response = await CheckService.ratio({
            koreanTrademarkName: name,
            englishTrademarkName: alphabet,
            classifications: classification ? [classification] : groups.map((c) => {
                return {
                    category: c.category,
                    primary: c.primary
                } as CategoryModel.ITrademarkCategoryClassificationModel
            })
        })
        if (response.status === 200) {
            const results: Array<CheckModel.ITrademarkCheckModel> = response.data
            const minPoint = Math.min(...results.map(r => r.point))
            const selectedCategory = results.find(r => r.point === minPoint)

            setSimilar(selectedCategory?.similarTrademarks as any)

            const r = Math.min(...results.map(r => r.point))
            setRatio(r <= 0 ? 10 : r)
            setCategory(categories.find(d => d.c4 === selectedCategory?.category)!)
            if (classification?.secondary) {
                setSecondaryCodes(classification.secondary)
            }

            if (classification && r >= 70) {
                setCount(count + 1)
            }
        } else {
            alert('상표 등록 가능성 검토에 실패하였습니다\n상표의 문구가 너무 짧은경우 더 자세히 작성해주세요!')
            navigate(-1)
        }

        // const results: Array<CheckModel.ITrademarkCheckModel> = [
        //     {
        //         "trademarkName": "스타벅스",
        //         "category": "식당/카페/베이커리",
        //         "primaryClassificationCode": "43",
        //         "secondaryClassificationCodes": null,
        //         "point": 27.8,
        //         "similarTrademarks": [
        //             {
        //                 "masterKey": "5620020012547",
        //                 "applicantName": "스타벅스 코포레이션",
        //                 "applicationStatus": "등록",
        //                 "title": "스타벅스",
        //                 "classificationCodes": [
        //                     "30",
        //                     "32"
        //                 ],
        //                 "imageUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=ed43a0609e94d6e234e1fcd03919711568b68549b3885dff9a98f905916be735ea74eef8c680b2e4bd5b6a1dd3301ca86dda3239d6a1636a8da6c8315c641204",
        //                 "thumbnailUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=ad7a17eeeef6e4eae3bd0d2e1a0a7d6baa8f369c549b6b7094e752cb36b57746c9edde41e10e07533d68b922a703828f45ca2d04521052d403de6bc6d1f56c97",
        //                 "applicationDate": "2002-10-04",
        //                 "registrationDate": "2003-04-29",
        //                 "publicationDate": null
        //             },
        //             {
        //                 "masterKey": "5020020011460",
        //                 "applicantName": "스타벅스 코포레이션",
        //                 "applicationStatus": "등록",
        //                 "title": "스타벅스",
        //                 "classificationCodes": [
        //                     "30",
        //                     "32"
        //                 ],
        //                 "imageUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=ed43a0609e94d6e234e1fcd0391971156739f0ac6f78d85377a423aa6330efa135969b5493ae7cd5512feff3c3fa94256b0fb75e424b815bac0cc9cda1a86462",
        //                 "thumbnailUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=ad7a17eeeef6e4eae3bd0d2e1a0a7d6bd40d8abfb71c1d50df3fb09c49e00950ac2eb23430188dff8f5e11c7e079a38605c92dce9a199ea9a7c67e7411447bb0",
        //                 "applicationDate": "2002-10-04",
        //                 "registrationDate": "2003-02-12",
        //                 "publicationDate": null
        //             },
        //             {
        //                 "masterKey": "4019920022251",
        //                 "applicantName": "스타벅스 코포레이션",
        //                 "applicationStatus": "등록",
        //                 "title": "스타벅스",
        //                 "classificationCodes": [
        //                     "30",
        //                     "32"
        //                 ],
        //                 "imageUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=ed43a0609e94d6e251697a9d72a91344c2f4ab5077f82256375fdf709af5f9b5a704663a65b9feadac23bbdbe57863d6",
        //                 "thumbnailUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=75a63eed5a438998fe897a23675ce4c3eccb6f44f0ef3f1d609f48186b94878b5a89c7295422844a36d342fd8d600ee7",
        //                 "applicationDate": "1992-08-13",
        //                 "registrationDate": "1993-08-18",
        //                 "publicationDate": "1993-06-21"
        //             }
        //         ]
        //     },
        //     {
        //         "trademarkName": "Starbucks",
        //         "category": "식당/카페/베이커리",
        //         "primaryClassificationCode": "43",
        //         "secondaryClassificationCodes": null,
        //         "point": 12,
        //         "similarTrademarks": [
        //             {
        //                 "masterKey": "4520120004853",
        //                 "applicantName": "스타벅스 코포레이션",
        //                 "applicationStatus": "등록",
        //                 "title": "STARBUCKS",
        //                 "classificationCodes": [
        //                     "30",
        //                     "32",
        //                     "43"
        //                 ],
        //                 "imageUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=ed43a0609e94d6e251697a9d72a913440ef5b27544fce4c30c2c791c453fe5a47d94ce08f32f9553373a49f2f9d04b30",
        //                 "thumbnailUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=75a63eed5a438998fe897a23675ce4c36ebe33eaff5c7e5d4aca14147be3e9417522bd60c9260d8f25737806f5043d8d",
        //                 "applicationDate": "2012-09-21",
        //                 "registrationDate": "2013-09-09",
        //                 "publicationDate": "2013-06-13"
        //             },
        //             {
        //                 "masterKey": "4020120051583",
        //                 "applicantName": "스타벅스 코포레이션",
        //                 "applicationStatus": "등록",
        //                 "title": "STARBUCKS",
        //                 "classificationCodes": [
        //                     "03",
        //                     "07",
        //                     "11"
        //                 ],
        //                 "imageUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=ed43a0609e94d6e251697a9d72a91344bb3a21d1402d2ea4179c9ddef3b7dc0de629b3945de2d77990016f01d81b4253",
        //                 "thumbnailUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=75a63eed5a438998fe897a23675ce4c3cbcae00bd7eb6ab615c94daa14134a4ae49ab47a28ab4676eef1165e3a76d986",
        //                 "applicationDate": "2012-08-16",
        //                 "registrationDate": "2013-07-11",
        //                 "publicationDate": "2013-05-02"
        //             },
        //             {
        //                 "masterKey": "5620050007185",
        //                 "applicantName": "스타벅스 코포레이션",
        //                 "applicationStatus": "등록",
        //                 "title": "STARBUCKS",
        //                 "classificationCodes": [
        //                     "07",
        //                     "09",
        //                     "11"
        //                 ],
        //                 "imageUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=ed43a0609e94d6e234e1fcd03919711523d613339e7cad78113a1cb51d7c1f38408bac41da421abd8ccf4bdede22665f365de8aab3530020a5ce10a5fc7e4873",
        //                 "thumbnailUrl": "http://plus.kipris.or.kr/kiprisplusws/fileToss.jsp?arg=ad7a17eeeef6e4eae3bd0d2e1a0a7d6b2c5600ca9d5b4a4b61a9118bc58c035633a41658ce51fcd89ed98e58575f70fcd5705d998343a42372d1f7d8353f752d",
        //                 "applicationDate": "2005-05-13",
        //                 "registrationDate": "2006-03-14",
        //                 "publicationDate": null
        //             }
        //         ]
        //     }
        // ]
        // const minPoint = Math.min(...results.map(r => r.point))
        // const selectedCategory = results.find(r => r.point === minPoint)

        // setSimilar(selectedCategory?.similarTrademarks as any)

        // const r = Math.min(...results.map(r => r.point))
        // setRatio(r)
        // setCategory(categories.find(d => d.c4 === selectedCategory?.category)!)
        // if (classification?.secondary) {
        //     setSecondaryCodes(classification.secondary)
        // }

        // if (classification && r >= 70) {
        //     setCount(count + 1)
        // }

        setLoading(false)
    }

    useEffect(() => {
        const userData = LocalStorageUtility.get('user')
        if (!userData) {
            alert('로그인 후 진행해주세요.')
            window.location.href = '/login'
        }

        getRatio()
    }, [])

    return (
        <>
            {isLoading && (
                <Container type={NavigationType.White}>
                    <Row
                        className='trademark-able-container'
                        style={{ height: '75vh' }}
                        justify={'center'}
                        align={'middle'}
                    >
                        <Col style={{ textAlign: 'center' }}>
                            <Space size={30} direction='vertical'>
                                <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
                                <Typography.Paragraph>
                                    <Typography.Text
                                        style={{
                                            fontSize: 18,
                                            color: '#878D91'
                                        }}
                                    >
                                        {`등록 가능성을 분석 중입니다.`}
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text
                                        style={{
                                            fontSize: 18,
                                            color: '#878D91'
                                        }}
                                    >
                                        {`잠시만 기다려주세요!`}
                                    </Typography.Text>
                                </Typography.Paragraph>
                            </Space>
                        </Col>
                    </Row>
                </Container >
            )}
            {
                !isLoading && (
                    <Container>
                        {ratio > 0 && (
                            <Row className='trademark-able-container'>
                                <Col span={24} className='header'>
                                    <LayoutContainer style={{ justifyContent: 'space-between', paddingTop: 110, }}>
                                        <Col span={24} style={{ alignSelf: 'center', }}>
                                            <Row>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Space size={0} direction='vertical'>
                                                        {name && (
                                                            <Typography.Text strong style={{ fontSize: 36, color: Color.Gray10, }}>
                                                                {`'${name}'${alphabet ? '' : '은(는)'}`}
                                                            </Typography.Text>
                                                        )}
                                                        {alphabet && (
                                                            <Typography.Text strong style={{ fontSize: 36, color: Color.Gray10, }}>
                                                                {`'${alphabet}'은(는)`}
                                                            </Typography.Text>
                                                        )}
                                                        <Typography.Text strong style={{ fontSize: 36, color: Color.Gray10, }}>
                                                            {`등록 가능성이 `}
                                                            <Typography.Text style={{ fontSize: 36, color: ratioColor(), }}>
                                                                {등록가능성문자열()}
                                                            </Typography.Text>
                                                        </Typography.Text>
                                                    </Space>
                                                </Col>
                                                <Col span={24} style={{ textAlign: 'center', marginTop: 40, }}>
                                                    <Typography.Text style={{ fontSize: 18, color: Color.Gray50, }}>
                                                        {`특허청 KIPRIS 상표 데이터와 실시간 연동`}
                                                    </Typography.Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center', alignSelf: 'end', }}>
                                            <div
                                                role="progressbar"
                                                aria-valuenow={ratio}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                style={{
                                                    "--value": ratio,
                                                    "--primary": ratioColor(),
                                                } as React.CSSProperties}
                                            >
                                                <Space
                                                    size={1}
                                                    direction='vertical'
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 12,
                                                    }}
                                                >
                                                    <Typography.Text style={{ fontSize: 18, color: Color.Gray10, }}>
                                                        {`등록가능성`}
                                                    </Typography.Text>
                                                    <CountUp
                                                        end={ratio}
                                                        suffix='%'
                                                        delay={0.5}
                                                        duration={2.5}
                                                        style={{
                                                            fontSize: 46,
                                                            color: ratioColor(),
                                                        }}
                                                    />
                                                </Space>
                                            </div>
                                        </Col>
                                    </LayoutContainer>
                                </Col>
                                <Col span={24} style={{ marginTop: 100, marginBottom: 100 }}>
                                    <LayoutContainer>
                                        <Col span={24} style={{ marginTop: 20, }}>
                                            <Row justify={'center'}>
                                                <Col
                                                    span={12}
                                                    style={{
                                                        padding: '30px 30px',
                                                        backgroundColor: Color.Gray20,
                                                        borderRadius: 8,

                                                    }}
                                                >
                                                    {등록가능성참고문자열()}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24} style={{ marginTop: 20, }}>
                                            <Row justify={'center'}>
                                                <Col
                                                    span={12}
                                                    style={{
                                                        padding: '18px 30px',
                                                        backgroundColor: Color.Gray20,
                                                        borderRadius: 8,
                                                    }}
                                                >
                                                    <Typography.Link
                                                        onClick={() => { setShowModal(true) }}
                                                    >
                                                        <Row justify={'space-between'}>
                                                            <Col span={20}>
                                                                <Typography.Text
                                                                    style={{
                                                                        fontSize: 20,
                                                                    }}
                                                                >
                                                                    전문가 매칭을 하게 되면 어떤 점이 좋아요?
                                                                </Typography.Text>
                                                            </Col>
                                                            <Col span={4} style={{ textAlign: 'end' }}>
                                                                <Typography.Text
                                                                    style={{
                                                                        fontSize: 20,
                                                                    }}
                                                                >
                                                                    {`>`}
                                                                </Typography.Text>
                                                            </Col>
                                                        </Row>
                                                    </Typography.Link>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24} style={{ marginTop: 40, }}>
                                            <Row justify={'center'}>
                                                <Col span={12}>
                                                    <Button
                                                        block
                                                        className='primary'
                                                        onClick={() => {
                                                            if (count >= 2 || ratio < 70) {
                                                            // if (count >= 2) {
                                                                setShow출원방법Modal(true)
                                                            } else {
                                                                setShow추가상품류Modal(true)
                                                            }
                                                        }}
                                                    >
                                                        {버튼문자열()}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {similar?.length > 0 && (
                                            <Col span={24} style={{ marginTop: 60, }}>
                                                <Row justify={'center'}>
                                                    <Col span={18}>
                                                        <Typography.Text
                                                            strong
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            검색하신 상표와 유사한 상표입니다.
                                                        </Typography.Text>
                                                    </Col>
                                                </Row>
                                                <Row justify={'center'} style={{ marginTop: 20, }}>
                                                    <Col span={18}>
                                                        <Table
                                                            rowKey={record => record.masterKey}
                                                            loading={isLoading}
                                                            pagination={false}
                                                            dataSource={similar ?? []}
                                                            columns={[
                                                                {
                                                                    title: "상표원본",
                                                                    dataIndex: "thumbnailUrl",
                                                                    key: "thumbnailUrl",
                                                                    align: 'center',
                                                                    render: (url: string) => {
                                                                        return (
                                                                            <img src={url} width={100} alt={'thumbnail'} />
                                                                        )
                                                                    },
                                                                },
                                                                {
                                                                    title: "상표명",
                                                                    dataIndex: "title",
                                                                    key: "title",
                                                                    align: 'center',
                                                                },
                                                                {
                                                                    title: "상품분류",
                                                                    dataIndex: "classificationCodes",
                                                                    key: "classificationCodes",
                                                                    align: 'center',
                                                                    render: codes => codes.join(', ')
                                                                },
                                                                {
                                                                    title: "출원인",
                                                                    dataIndex: "applicantName",
                                                                    key: "applicantName",
                                                                    align: 'center',
                                                                },
                                                                {
                                                                    title: "출원정보",
                                                                    dataIndex: "applicationStatus",
                                                                    key: "applicationStatus",
                                                                    align: 'center',
                                                                    render: (status) => {
                                                                        if (status === '등록') {
                                                                            return <Tag color={'blue'}>{status}</Tag>
                                                                        } else if (status === '무효') {
                                                                            return <Tag color={'yellow'}>{status}</Tag>
                                                                        } else if (status === '거절') {
                                                                            return <Tag color={'red'}>{status}</Tag>
                                                                        }

                                                                        return (
                                                                            <Tag>{status}</Tag>
                                                                        )
                                                                    }
                                                                },
                                                                {
                                                                    title: "출원일자",
                                                                    dataIndex: "applicationDate",
                                                                    key: "applicationDate",
                                                                    render: (date: string) => {
                                                                        return (
                                                                            DateUtility.date_format(date)
                                                                        )
                                                                    },
                                                                    align: 'center',
                                                                },
                                                            ]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                    </LayoutContainer>
                                </Col>
                            </Row>
                        )}

                        {category && (
                            <출원방법선택Modal
                                open={show출원방법Modal}
                                name={name}
                                alphabet={alphabet}
                                categories={categories}
                                secondaryCodes={secondaryCodes}
                                ratio={ratio}
                                onSelected={(type: 출원방법타입.출원방법, codes: Array<string>) => {
                                    navigate('/payment/trademarks', {
                                        state: {
                                            type: type!,
                                            name: name,
                                            alphabet: alphabet,
                                            description: description,
                                            codes: codes,
                                        }
                                    })
                                }}
                                onClosed={() => { setShow출원방법Modal(false) }}
                            />
                        )}
                        {category && (
                            <추가상품류Modal
                                open={show추가상품류Modal}
                                category={category}
                                secondaryCodes={secondaryCodes}
                                onNext={() => {
                                    setShow출원방법Modal(true)
                                    setShow추가상품류Modal(false)
                                }}
                                onSearch={(classification) => {
                                    setShow추가상품류Modal(false)

                                    getRatio(classification)
                                }}
                                onClosed={() => { setShow추가상품류Modal(false) }}
                            />
                        )}
                        <전문가Modal
                            open={showModal}
                            onClosed={() => { setShowModal(false) }}
                        />
                    </Container>
                )
            }
        </>
    )
}
import { Col, Form, Input } from "antd"
import React, { CSSProperties, FunctionComponent } from "react"
import { FormModel } from "../../models/FormModel"
import { Rule } from "antd/es/form"

interface IProps extends FormModel.IFormBaseProps {
    allowClear?: boolean
    rules?: Rule[]
    style?: CSSProperties
    children?: React.ReactNode
    onPressEnter?: () => void
}

export const InputForm: FunctionComponent<IProps> = (props) => {
    const { name, label, placeholder, layout, allowClear = false, rules, style, children, onPressEnter } = props
    const formLayout = FormModel.layout(layout)

    return (
        <Col {...formLayout}>
            <Form.Item
                label={label || ''}
                name={name}
                rules={rules}
            >
                <Input
                    allowClear={allowClear}
                    placeholder={placeholder || "검색어를 입력하세요"}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if ((e.key === "Enter" || e.keyCode === 13) && onPressEnter) {
                            onPressEnter()
                        }
                    }}
                    style={style}
                />
                {children}
            </Form.Item>
        </Col>
    )
}
import { Col, Layout, Row, Space, Typography } from "antd"
import React from "react"

export const RefundTerm: React.FunctionComponent = () => {
    return (
        <Row>
            <Col xs={{ span: 24 }} lg={{ span: 12, offset: 6 }} style={{ backgroundColor: '#fff', marginTop: 30, marginBottom: 30, paddingLeft: 12, paddingRight: 12 }}>
                <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 24,
                            }}
                        >
                            {`나혼자상표 환불정책`}
                        </Typography.Text>
                    </Col>
                </Row>
                <Row style={{ marginTop: 30, }}>
                    <Col span={24}>
                        <Layout.Content style={{}}>
                            <Typography.Title level={4}>
                                {'<바로출원 서비스 환불 정책>'}
                            </Typography.Title>
                            <Typography.Paragraph>
                                <Space direction="vertical">
                                    <Typography.Title level={5}>
                                        {'1) 100% 환불되는 경우'}
                                    </Typography.Title>
                                    <Typography.Text type="secondary">• 출원매니저와 상담 전</Typography.Text>
                                    <Typography.Text type="secondary">• 출원매니저 상담 시 출원 절차 진행을 하지 않는다는 의사를 전달하는 경우</Typography.Text>
                                </Space>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Space direction="vertical">
                                    <Typography.Title level={5}>
                                        {'2) 출원관납료만 환불되는 경우'}
                                    </Typography.Title>
                                    <Typography.Text type="secondary">• 출원매니저와 상담을 통해 출원 절차 진행하고, 특허청 출원서 제출 전까지 환불을 요청한 경우</Typography.Text>
                                </Space>
                            </Typography.Paragraph>

                            <Typography.Paragraph>
                                <Space direction="vertical">
                                    <Typography.Title level={5}>
                                        {'3) 환불 불가한 경우'}
                                    </Typography.Title>
                                    <Typography.Text type="secondary">• 특허청 출원서 제출 후</Typography.Text>
                                    <Typography.Text type="secondary">※ 특허청 상표등록 심사결과 거절 결정이 되더라도 별도 환불금은 없음.</Typography.Text>
                                </Space>
                            </Typography.Paragraph>
                        </Layout.Content>
                        <Layout.Content style={{ marginTop: 20, }}>
                            <Typography.Title level={4}>
                                {'<간편 출원/심사출원 서비스 환불 정책>'}
                            </Typography.Title>
                            <Typography.Paragraph>
                                <Space direction="vertical">
                                    <Typography.Title level={5}>
                                        {'1) 100% 환불되는 경우'}
                                    </Typography.Title>
                                    <Typography.Text type="secondary">• 출원매니저와 상담 전</Typography.Text>
                                    <Typography.Text type="secondary">• 출원매니저 상담 시 출원 절차 진행을 하지 않는다는 의사를 전달하는 경우</Typography.Text>
                                </Space>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Space direction="vertical">
                                    <Typography.Title level={5}>
                                        {'2) 출원료 일부 및 출원관납료가 환불되는 경우'}
                                    </Typography.Title>
                                    <Typography.Text type="secondary">• 출원매니저와 상담을 통해 출원 절차 진행하고, 추후 진행된 사항을 제한 금액</Typography.Text>
                                    <Typography.Text type="secondary">(상표 등록 자문 : 15,000원, 상표 권리 범위 설정 : 15,000원, 리포트 제공 : 40,000원)</Typography.Text>
                                </Space>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Space direction="vertical">
                                    <Typography.Title level={5}>
                                        {'3) 출원관납료만 환불되는 경우'}
                                    </Typography.Title>
                                    <Typography.Text type="secondary">• 특허청 출원서 제출을 진행하는 과정에서 환불을 요청한 경우</Typography.Text>
                                </Space>
                            </Typography.Paragraph>

                            <Typography.Paragraph>
                                <Space direction="vertical">
                                    <Typography.Title level={5}>
                                        {'3) 환불 불가한 경우'}
                                    </Typography.Title>
                                    <Typography.Text type="secondary">• 특허청 출원서 제출 후</Typography.Text>
                                    <Typography.Text type="secondary">※ 특허청 상표등록 심사결과 거절 결정이 되더라도 별도 환불금은 없음.</Typography.Text>
                                </Space>
                            </Typography.Paragraph>
                        </Layout.Content>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

import { Col, Row, Space, Typography } from 'antd'
import { FunctionComponent, useState } from 'react'
import { AssetImage } from '../../assets/image/Index'
import { Color } from '../../styles/Color'
import { MenuType } from '../../types/MenuType'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'
import { 앱다운로드 } from '../etc/앱다운로드'

enum 절차안내Menu {
    상표 = '상표',
    도메인 = '도메인'
}

export const 절차안내: FunctionComponent = () => {
    const [menu, setMenu] = useState<절차안내Menu>(절차안내Menu.상표)
    const menus = [절차안내Menu.상표, 절차안내Menu.도메인]
    const contents = [
        {
            menu: 절차안내Menu.상표,
            title: '상표 출원 진행 절차',
            images: [
                AssetImage.background.process.상표.출원의뢰,
                AssetImage.background.process.상표.출원진행,
                AssetImage.background.process.상표.심사,
                AssetImage.background.process.상표.심사결과발표,
                AssetImage.background.process.상표.등록완료,
            ]
        },
        {
            menu: 절차안내Menu.도메인,
            title: '도메인 등록 진행 절차',
            images: [
                AssetImage.background.process.도메인.이름선택,
                AssetImage.background.process.도메인.확장자선택,
                AssetImage.background.process.도메인.구매하기,
            ]
        },
    ]

    return (
        <Container menu={MenuType.절차안내}>
            <Row className='service-container'>
                <Col span={24} className='header'>
                    <LayoutContainer>
                        <Col span={12} style={{ alignSelf: 'center', marginTop: 100, }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, color: Color.Gray10, }}>{`출원부터 등록까지`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, color: Color.Gray10, }}>{`간단하게 해결하세요!`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Gray10, }}>{`나혼자상표의 절차 안내`}</Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} style={{ marginTop: 40, alignSelf: 'end', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.process.main}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ alignSelf: 'center', marginTop: 60, }}>
                    <LayoutContainer>
                        <Col span={24} style={{ alignSelf: 'center', }}>
                            <Space size={0} direction='vertical'>
                                <Typography.Text style={{ fontSize: 28, fontWeight: 700, }}>{'상표와 도메인의'}</Typography.Text>
                                <Typography.Text style={{ fontSize: 28, fontWeight: 700, }}>{'진행 절차를 알려드릴게요'}</Typography.Text>
                            </Space>
                        </Col>
                        <Col span={24} style={{ marginTop: 30, }}>
                            <Space size={16}>
                                {menus.map((t) => {
                                    return (
                                        <Typography.Link
                                            key={t}
                                            style={{
                                                fontSize: 23,
                                                color: t === menu ? Color.Gray10 : Color.Blue60,
                                                borderWidth: 1,
                                                borderRadius: 20,
                                                borderStyle: 'solid',
                                                borderColor: Color.Blue60,
                                                paddingTop: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                backgroundColor: t === menu ? Color.Blue60 : Color.Transparent
                                            }}
                                            onClick={() => { setMenu(t) }}
                                        >
                                            {t}
                                        </Typography.Link>
                                    )
                                })}
                            </Space>
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24}>
                    {contents
                        .filter(c => c.menu === menu)
                        .map((c) => {
                            return (
                                <LayoutContainer
                                    key={c.menu}
                                    style={{ justifyContent: 'space-between', marginTop: 40, }}
                                >
                                    {/* <Col span={24} style={{ alignSelf: 'center', }}>
                                        <Typography.Text style={{ fontSize: 28, fontWeight: 700, }}>{c.title}</Typography.Text>
                                    </Col> */}
                                    <Col span={24} style={{ alignSelf: 'center', marginTop: 20, }}>
                                        <Space size={40} direction='vertical'>
                                            {c.images.map((i) => {
                                                return (
                                                    <img
                                                        width={'100%'}
                                                        src={i}
                                                        alt='참고이미지' />
                                                )
                                            })}
                                        </Space>
                                    </Col>
                                </LayoutContainer>
                            )
                        })}
                </Col>
                <Col
                    span={24}
                    style={{
                        marginTop: 160,
                        backgroundColor: Color.Blue80,
                        paddingTop: 50,
                        paddingBottom: 50,
                        textAlign: 'center',
                    }}>
                    <앱다운로드 />
                </Col>
            </Row>
        </Container>
    )
}
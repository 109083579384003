
import { FunctionComponent, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LocalStorageUtility from '../../../utils/LocalStorageUtility'

export const PaymentSuccess: FunctionComponent = () => {
    const { id } = useParams()

    useEffect(() => {
        console.log(id)
        if (!id) {
            window.location.href = '/'
        } else {
            LocalStorageUtility.remove(id)
        }

        alert('결제가 성공하였습니다.')
        window.location.href = '/user/trademark-process'
    }, [])

    return (
        <>
        </>
    )
}

import { Col, Space, Typography } from 'antd'
import { FunctionComponent } from 'react'
import { AssetImage } from '../../assets/image/Index'
import { Color } from '../../styles/Color'
import { LayoutContainer } from '../layout/LayoutContainer'

export const 앱다운로드: FunctionComponent = () => {
    return (
        <LayoutContainer style={{ justifyContent: 'center', }}>
            <Col span={24} style={{ textAlign: 'center' }}>
                <Space size={1} direction='vertical'>
                    <Typography.Text strong style={{ fontSize: 30, color: Color.Gray10, }}>{`간단한 상표 출원,`}</Typography.Text>
                    <Typography.Text strong style={{ fontSize: 30, color: Color.Gray10, }}>{`나혼자상표와 함께`}</Typography.Text>
                </Space>
            </Col>
            <Col span={24} style={{ textAlign: 'center', marginTop: 30, }}>
                <Space size={20}>
                    <Typography.Link
                        onClick={() => {
                            window.location.href = 'https://apps.apple.com/kr/app/%EB%82%98%ED%98%BC%EC%9E%90%EC%83%81%ED%91%9C/id6449414426'
                        }}
                    >
                        <img width={150} src={AssetImage.로고.outline.ios} alt={'ios'} />
                    </Typography.Link>
                    <Typography.Link
                        onClick={() => {
                            window.location.href = 'https://play.google.com/store/apps/details?id=com.markerslab.atm'
                        }}
                    >
                        <img width={150} src={AssetImage.로고.outline.aos} alt={'aos'} />
                    </Typography.Link>
                </Space>
            </Col>
        </LayoutContainer>
    )
}
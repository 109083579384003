import { Row } from 'antd'
import { CSSProperties, ReactNode } from 'react'

export const LayoutContainer = (props: {
    children?: ReactNode,
    style?: CSSProperties
}) => {
    const { children, style } = props
    return (
        <Row className='layout-container' style={style}>
            {children}
        </Row>

    )
}
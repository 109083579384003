export const AssetImage = {
    background: {
        mobile: require('./background/mobile.png'),
        qna: require('./background/qna.png'),
        company: {
            people: require('./background/company/company-people.png'),
            협력사: require('./background/company/partner.png'),
            left: require('./background/company/left.png'),
            right: require('./background/company/right.png'),
            considerate: require('./background/company/considerate.png'),
            effective: require('./background/company/effective.png'),
            reliable: require('./background/company/reliable.png'),
        },
        service: {
            main: require('./background/service/main.png'),
            first: require('./background/service/first.png'),
            second: require('./background/service/second.png'),
            third: require('./background/service/third.png'),
            forth: require('./background/service/forth.png'),
        },
        process: {
            main: require('./background/process/main.png'),
            상표: {
                출원의뢰: require('./background/process/brand/request.png'),
                출원진행: require('./background/process/brand/process.png'),
                심사: require('./background/process/brand/judge.png'),
                심사결과발표: require('./background/process/brand/presentation.png'),
                등록완료: require('./background/process/brand/complete.png'),
            },
            도메인: {
                이름선택: require('./background/process/domain/name.png'),
                확장자선택: require('./background/process/domain/extension.png'),
                구매하기: require('./background/process/domain/payment.png'),
            }
        },
        search: {
            상표검색: require('./background/search/search.png'),
        }
    },
    banner: {
        home: {
            전문가: require('./banner/expert.png'),

            직접출원: require('./banner/direct.png'),
            바로출원: require('./banner/now.png'),
            간편출원: require('./banner/simple.png'),
            심화출원: require('./banner/intensify.png'),

            상표등록절차: require('./banner/process.png'),
        },
        상표검색: {
            지정상품: require('./banner/product.png'),
        },
        전문가: {
            변리사:  require('./banner/expert/attorney.png'),
            가능성:  require('./banner/expert/possible.png'),
            자문:  require('./banner/expert/consulting.png'),
            범위설정:  require('./banner/expert/range.png'),
            출원:  require('./banner/expert/application.png'),
        }
    },
    로고: {
        aos: require('./logo/ios-black.png'),
        ios: require('./logo/aos-black.png'),
        outline: {
            aos: require('./logo/aos-outline.png'),
            ios: require('./logo/ios-outline.png'),
        }
    },
    mobile: {
        kakao: require('./mobile/kakao.png'),
        naver: require('./mobile/naver.png'),
        apple: require('./mobile/apple.png'),
        google: require('./mobile/google.png'),
    },
    guide: {
        stamp: require('./guide/stamp.png'),
    }
}
import { useGoogleLogin } from '@react-oauth/google'
import { Col, Row, Space, Typography } from 'antd'
import { FunctionComponent, useState } from 'react'
import { AssetImage } from '../../assets/image/Index'
import { 이용약관동의Modal } from '../../components/modal/이용약관동의Modal'
import { MenuType } from '../../types/MenuType'
import { NavigationType } from '../../types/NavigationType'
import { SocialType } from '../../types/SocialType'
import LocalStorageUtility from '../../utils/LocalStorageUtility'
import { Container } from '../layout/Container'

declare const Kakao: any
declare const naver_id_login: any

export const 로그인: FunctionComponent = () => {
    const [socialType, setSocialType] = useState<SocialType | null>()

    const mobiles = [
        { type: SocialType.Kakao, image: AssetImage.mobile.kakao, },
        { type: SocialType.Naver, image: AssetImage.mobile.naver, },
        { type: SocialType.Google, image: AssetImage.mobile.google, },
        // { type: SocialType.Apple, image: AssetImage.mobile.apple, },
    ]

    const isProduction = process.env.NODE_ENV === 'production'

    const googleLogin = useGoogleLogin({
        onSuccess: (res) => {
            fetch('https://api.markerslab.app/auth/google', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    accessToken: res.access_token
                })
            })
                .then(res => res.json())
                .then((json) => {
                    LocalStorageUtility.set('user', json)
                    window.location.href = '/'
                })
        },
    })

    return (
        <Container type={NavigationType.White} menu={MenuType.로그인}>
            <Row className='login-container' justify={'center'} align={'middle'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <div>
                        <Typography.Text
                            style={{
                                fontSize: 24,
                                fontWeight: 700,
                            }}
                        >
                            {`로그인 / 회원가입`}
                        </Typography.Text>
                    </div>
                    <Space direction='vertical' style={{ marginTop: 30 }}>
                        {mobiles.map((m) => {
                            return (
                                <div
                                    key={m.type}
                                    id={m.type === SocialType.Naver ? 'naver_id_login' : ''}
                                >
                                    <Typography.Link
                                        key={m.type}
                                        onClick={() => {
                                            setSocialType(m.type)
                                        }}
                                    >
                                        <img
                                            src={m.image}
                                            alt='모바일'
                                            width={370}
                                        />
                                    </Typography.Link>
                                </div>
                            )
                        })}
                    </Space>
                </Col>
            </Row>

            {socialType && (
                <이용약관동의Modal
                    type={socialType}
                    onCompleted={(type) => {
                        switch (type) {
                            case SocialType.Kakao:
                                Kakao.Auth.authorize({
                                    redirectUri: isProduction ? 'https://markerslab.app/kakao/oauth' : 'http://localhost:3000/kakao/oauth',
                                })
                                break
                            case SocialType.Naver:
                                const clientId = 'NNzFQ8P_6jnPhaKACAGd'
                                const callback = isProduction ? 'https://markerslab.app/naver/oauth' : 'http://localhost:3000/naver/oauth'
                                const naver = new naver_id_login(clientId, callback)
                                const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${clientId}&redirect_uri=${callback}&state=${naver.getUniqState()}`

                                window.location.href = url
                                break
                            case SocialType.Google:
                                googleLogin()
                                break
                        }
                    }}
                    onClosed={() => { setSocialType(null) }}
                />
            )}
        </Container>
    )
}
import { Row, Col, Space, Typography } from 'antd';
import { LayoutContainer } from './LayoutContainer';
import { Vector } from '../../assets/svg/Index';
import { Color } from '../../styles/Color';

export const Footer = () => (
    <Row className={`footer-container`}>
        <Col span={24} style={{ alignSelf: 'center' }}>
            <LayoutContainer>
                <Col span={24} className='footer-container'>
                    <Typography.Paragraph style={{ marginTop: '5px', marginBottom: '5px', }}>
                        <Space>
                            <img src={Vector.logo.white} alt='logo' width={40} />
                            <Typography.Text strong style={{ color: Color.Gray10 }}>나혼자상표</Typography.Text>
                        </Space>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        <Typography.Text>ⓒ 2024. 나혼자상표 All Rights Reserved.</Typography.Text>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        <Space size={1} direction='vertical'>
                            <Typography.Text>상호: 올.원 마크(Al.one mark)</Typography.Text>
                            <Typography.Text>대표: 김대종</Typography.Text>
                            <Typography.Text>사업자등록번호 : 587-47-01044 | 통신판매업신고번호 : 2024-인천연수구-3401 </Typography.Text>
                            <Typography.Text>이메일: leader@markerslab.app | 주소: 인천광역시 연수구 인천타워대로 323, B동 브이430(송도동, 송도 센트로드)</Typography.Text>
                            <Typography.Text>유선번호: 02-6964-6105</Typography.Text>
                            <Typography.Text className='item'>
                                <Space split={'|'}>
                                    <Typography.Link href='/terms/privacy.html' target='_blank'>개인정보 처리방침</Typography.Link>
                                    <Typography.Link href='/terms/service.html' target='_blank'>서비스 이용 약관</Typography.Link>
                                    <Typography.Link href='/terms/refund.html' target='_blank'>환불 정책 약관</Typography.Link>
                                </Space>
                            </Typography.Text>
                        </Space>
                    </Typography.Paragraph>
                </Col>
            </LayoutContainer>
        </Col>
    </Row>
)

import React from "react";
import { Col, Layout, Row, Space, Typography } from "antd";
const { Text, Paragraph, Title } = Typography;

export const ServiceTerm: React.FunctionComponent = () => {
    return (
        <Row>
            <Col xs={{ span: 24 }} lg={{ span: 12, offset: 6 }} style={{ backgroundColor: '#fff', marginTop: 30, marginBottom: 30, paddingLeft: 12, paddingRight: 12 }}>
                <Space direction="vertical" size="large">
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>이용약관</Title>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>1제1조(목적)</Title>
                        <Paragraph>
                            <Text type="secondary">
                                이 약관은 올.원 마크(사업자)가 운영하는 올.원 마크 사이트(이하 “올.원 마크”이라 한다)에서 제공하는 상표 출원 대행 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
                            </Text>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제2조(정의)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크란 사업자가 서비스를 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 제공할 수 있는 가상의 영업장을 의미합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 이용자란 에 접속하여 이 약관에 따라 가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 회원이라 함은 에 회원등록을 한 자로서, 계속적으로 올.원 마크가 제공하는 서비스를 이용할 수 있는 자를 말합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>4. 비회원이라 함은 회원에 가입하지 않고 올.원 마크가 제공하는 서비스를 이용하는 자를 말합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제3조 (약관 등의 명시와 설명 및 개정)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 올.원 마크의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 올.원 마크는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>4. 올.원 마크가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 올.원 마크의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 올.원 마크는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>5. 올.원 마크가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 올.원 마크에 송신하여 올.원 마크의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>6. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제4조(서비스의 제공 및 변경)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 다음과 같은 업무를 수행합니다.</Title>
                                <Text type="secondary">1. 상표출원 대행 서비스</Text>
                                <Text type="secondary">2. 상표출원 중개 서비스</Text>
                                <Text type="secondary">3. 상표 검색 서비스</Text>
                                <Text type="secondary">4. 기타 올.원 마크가 정하는 업무</Text>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 서비스의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 서비스 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 서비스 또는 용역의 내용 및 제공일자를 명시하여 현재의 서비스 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 올.원 마크가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 서비스등의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>4. 전항의 경우 올.원 마크는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, 올.원 마크가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제5조(서비스의 중단)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, 올.원 마크가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 올.원 마크는 제8조에 정한 방법으로 이용자에게 통지하고 당초 올.원 마크에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, 올.원 마크가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 올.원 마크에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제6조(회원가입)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 이용자는 올.원 마크가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.</Title>
                                <Text type="secondary">1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 올.원 마크의 회원재가입 승낙을 얻은 경우에는 예외로 한다.</Text>
                                <Text type="secondary">2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</Text>
                                <Text type="secondary">3. 기타 회원으로 등록하는 것이 올.원 마크의 기술상 현저히 지장이 있다고 판단되는 경우</Text>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 회원가입계약의 성립 시기는 올.원 마크의 승낙이 회원에게 도달한 시점으로 합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>4. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 올.원 마크에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제7조(회원 탈퇴 및 자격 상실 등)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 회원은 올.원 마크에 언제든지 탈퇴를 요청할 수 있으며 올.원 마크는 즉시 회원탈퇴를 처리합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 회원이 다음 각 호의 사유에 해당하는 경우, 올.원 마크는 회원자격을 제한 및 정지시킬 수 있습니다.</Title>
                                <Text type="secondary">1. 가입 신청 시에 허위 내용을 등록한 경우</Text>
                                <Text type="secondary">2. 올.원 마크를 이용하여 구입한 서비스등의 대금, 기타 올.원 마크 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</Text>
                                <Text type="secondary">3. 다른 사람의 올.원 마크 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</Text>
                                <Text type="secondary">4. 올.원 마크를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</Text>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 올.원 마크가 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 올.원 마크는 회원자격을 상실시킬 수 있습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>4. 올.원 마크가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제8조(회원에 대한 통지)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크가 회원에 대한 통지를 하는 경우, 회원이 올.원 마크와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 불특정다수 회원에 대한 통지의 경우 1주일이상 올.원 마크 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제9조(구매신청 및 개인정보 제공 동의 등)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크 이용자는 올.원 마크 상에서 다음 또는 이와 유사한 방법에 의하여 서비스 이용을 신청하며, 올.원 마크는 이용자가 서비스 이용 신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.</Title>
                                <Text type="secondary">1. 서비스등의 검색 및 선택</Text>
                                <Text type="secondary">2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</Text>
                                <Text type="secondary">3. 약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인</Text>
                                <Text type="secondary">4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)</Text>
                                <Text type="secondary">5. 서비스등의 구매신청 및 이에 관한 확인 또는 올.원 마크의 확인에 대한 동의</Text>
                                <Text type="secondary">6. 결제방법의 선택</Text>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 올.원 마크가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제10조 (계약의 성립)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 제9조와 같은 서비스 이용 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.</Title>
                                <Text type="secondary">1. 신청 내용에 허위, 기재누락, 오기가 있는 경우</Text>
                                <Text type="secondary">2. 기타 구매신청에 승낙하는 것이 올.원 마크 기술상 현저히 지장이 있다고 판단하는 경우</Text>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 올.원 마크의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제11조(지급방법)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Text type="secondary">올.원 마크에서 구매한 서비스 이용에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다. 단, 올.원 마크는 이용자의 지급방법에 대하여 서비스등의 대금에 어떠한 명목의 수수료도  추가하여 징수할 수 없습니다.</Text>
                                <Text type="secondary">1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</Text>
                                <Text type="secondary">2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</Text>
                                <Text type="secondary">3. 온라인무통장입금</Text>
                                <Text type="secondary">4. 전자화폐에 의한 결제</Text>
                                <Text type="secondary">5. 수령 시 대금지급</Text>
                                <Text type="secondary">6. 마일리지 등 올.원 마크가 지급한 포인트에 의한 결제</Text>
                                <Text type="secondary">7. 올.원 마크와 계약을 맺었거나 올.원 마크가 인정한 상품권에 의한 결제</Text>
                                <Text type="secondary">8. 기타 전자적 지급 방법에 의한 대금 지급 등</Text>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제12조(수신확인통지․구매신청 변경 및 취소)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 이용자의 서비스 이용 신청이 있는 경우 이용자에게 수신확인통지를 합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 올.원 마크는 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제13조(서비스등의 공급)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 이용자와 서비스등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 서비스등을 제공할 수 있도록 필요한 조치를 취합니다. 다만, 올.원 마크가 이미 서비스등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 올.원 마크는 이용자가 서비스등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 이용자가 신청한 서비스에 대한 정보 등을 명시합니다. 만약 올.원 마크가 약정 서비스 제공기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 올.원 마크가 고의․과실이 없음을 입증한 경우에는 그러하지 아니합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제14조(환급)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Text type="secondary">올.원 마크는 이용자가 신청한 서비스를 적절히 제공하지 못할 사유가 있어 제공할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 서비스등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.</Text>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제15조(청약철회 등)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크와 서비스등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 서비스등의 공급이 늦게 이루어진 경우에는 서비스등을 공급받거나 서비스등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 이용자는 올.원 마크가 서비스 제공의 준비를 시작한 후 이용자에게 책임 있는 사유로 서비스의 제공이 불가능한 경우에는 취소를 할 수 없습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 이용자는 제1항 및 제2항의 규정에 불구하고 서비스등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 서비스등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제16조(청약철회 등의 효과)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 이용자로부터 정당한 사유로 최소를 요청받은 경우 등을 3영업일 이내에 이미 지급받은 서비스등의 대금을 환급합니다. 이 경우 올.원 마크가 이용자에게 서비스등의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 서비스등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 서비스등의 대금의 청구를 정지 또는 취소하도록 요청합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 청약철회 등의 경우 공급받은 서비스등의 반환에 필요한 비용은 이용자가 부담합니다. 올.원 마크는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 서비스등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 서비스등의 반환에 필요한 비용은 올.원 마크가 부담합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>4. 이용자가 서비스등을 제공받을 때 발송비를 부담한 경우에 올.원 마크는 청약철회 시 그 비용을  누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제17조(개인정보보호)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 올.원 마크는 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>4. 올.원 마크는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>5. 올.원 마크가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>6. 이용자는 언제든지 올.원 마크가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 올.원 마크는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 올.원 마크는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>7. 올.원 마크는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를  최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>8. 올.원 마크 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>9. 올.원 마크는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제18조(올.원 마크의 의무)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스․용역을 제공하는데 최선을 다하여야 합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 올.원 마크가 서비스나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>4. 올.원 마크는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제19조(회원의 ID 및 비밀번호에 대한 의무)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 올.원 마크에 통보하고 올.원 마크의 안내가 있는 경우에는 그에 따라야 합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제20조(이용자의 의무) </Title>
                        <Paragraph>
                            <Text type="secondary">
                                이용자는 다음 행위를 하여서는 안 됩니다.
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Text type="secondary">1. 신청 또는 변경시 허위 내용의 등록</Text>
                                <Text type="secondary">2. 타인의 정보 도용</Text>
                                <Text type="secondary">3. 올.원 마크에 게시된 정보의 변경</Text>
                                <Text type="secondary">4. 올.원 마크가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</Text>
                                <Text type="secondary">5. 올.원 마크 기타 제3자의 저작권 등 지적재산권에 대한 침해</Text>
                                <Text type="secondary">6. 올.원 마크 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</Text>
                                <Text type="secondary">7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 올.원 마크에 공개 또는 게시하는 행위</Text>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제21조(저작권의 귀속 및 이용제한)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크가 작성한 저작물에 대한 저작권 기타 지적재산권은 올.원 마크에 귀속합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 이용자는 올.원 마크를 이용함으로써 얻은 정보 중 올.원 마크에게 지적재산권이 귀속된 정보를 올.원 마크의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 올.원 마크는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제22조(분쟁해결)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>3. 올.원 마크와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                    <Layout.Content style={{ backgroundColor: '#fff' }}>
                        <Title level={4}>제23조(재판권 및 준거법)</Title>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>1. 올.원 마크와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.</Title>
                            </Space>
                        </Paragraph>
                        <Paragraph>
                            <Space direction="vertical">
                                <Title level={5}>2. 올.원 마크와 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.</Title>
                            </Space>
                        </Paragraph>
                    </Layout.Content>
                </Space>
            </Col>
        </Row>
    );
};

import { Button, Col, Modal, Row, Space, Typography } from "antd"
import { FunctionComponent, useState } from "react"
import { CategoryModel } from "../../models/CategoryModel"
import { 연관상품류Modal } from "./연관상품류Modal"

interface IProps {
    open: boolean
    category: CategoryModel.ITrademarkCategoryFlattenModel
    secondaryCodes?: Array<string>
    onNext: () => void
    onSearch: (classification: CategoryModel.ITrademarkCategoryClassificationModel) => void
    onClosed: () => void
}

export const 추가상품류Modal: FunctionComponent<IProps> = (props) => {
    const { open, category, secondaryCodes, onNext, onSearch, onClosed } = props

    const [showModal, setShowModal] = useState(false)

    const Footer = () => {
        return (
            <Row gutter={[16, 0]} style={{ paddingTop: 40, }}>
                <Col span={12}>
                    <Button
                        block
                        className="secondary"
                        onClick={onNext}
                    >
                        그냥 출원할래요
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        block
                        className="primary"
                        onClick={() => {
                            setShowModal(true)
                        }}
                    >
                        추가로 검색해 볼래요
                    </Button>
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            open={open}
            destroyOnClose={true}
            onCancel={() => { onClosed() }}
            footer={<Footer />}
        >
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Space size={1} direction="vertical">
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 24,
                            }}
                        >
                            {`🙌🏻 잠깐!`}
                        </Typography.Text>
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 24,
                            }}
                        >
                            {`참고해주세요!`}
                        </Typography.Text>
                    </Space>
                </Col>
                <Col span={24}>
                    <Space size={1} direction="vertical">
                        <Typography.Text
                            style={{
                                fontSize: 20,
                            }}
                        >
                            {`위 상표는 다른 연관된 상품류를 가지고 있어요.`}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontSize: 20,
                            }}
                        >
                            {`고객님이 더 넓은 분야에서 상표의 권리를 보호받고 싶으시다면, `}
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 20,
                                }}
                            >
                                {`추가로 검색해 볼래요`}

                            </Typography.Text>
                            {'를 통하여 더 넓은 권리 범위를 가지는 상표를 검색해 보세요.'}
                        </Typography.Text>
                    </Space>
                </Col>
            </Row>

            <연관상품류Modal
                open={showModal}
                category={category}
                secondaryCodes={secondaryCodes}
                onCompleted={(classification) => {
                    setShowModal(false)
                    onSearch(classification)
                }}
                onClosed={() => { setShowModal(false) }}
            />
        </Modal>
    )
}
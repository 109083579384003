
import { Col, Row, Space, Typography } from 'antd'
import { FunctionComponent } from 'react'
import ReactPlayer from 'react-player/youtube'
import { MenuType } from '../../types/MenuType'
import { NavigationType } from '../../types/NavigationType'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'

export const 직접출원: FunctionComponent = () => {
    return (
        <Container type={NavigationType.White} menu={MenuType.상표등록}>
            <Row className="direct-trademark-container" justify={'center'} align={'middle'}>
                <Col span={24}>
                    <LayoutContainer style={{ gap: 20, }}>
                        <Col span={24}>
                            <Space direction='vertical'>
                                <Typography.Text
                                    style={{
                                        fontSize: 28,
                                    }}
                                >
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 28,
                                        }}
                                    >
                                        {`직접 출원`}
                                    </Typography.Text>
                                    {`하는`}
                                </Typography.Text>
                                <Typography.Text
                                    style={{
                                        fontSize: 28,
                                    }}
                                >
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 28,
                                        }}
                                    >
                                        {`방법`}
                                    </Typography.Text>
                                    {`을 알려드릴게요!`}
                                </Typography.Text>
                            </Space>
                        </Col>
                        <Col span={24}>
                            <ReactPlayer
                                url='https://www.youtube.com/watch?v=RDiwXxlTxV0'
                                width={"100%"}
                                height={"550px"}
                            />
                        </Col>
                    </LayoutContainer>
                </Col>
            </Row>
        </Container>
    )
}
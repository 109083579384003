
import { FunctionComponent, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import LocalStorageUtility from '../../utils/LocalStorageUtility'

export const NaverOAuth: FunctionComponent = () => {
    const [params] = useSearchParams()

    useEffect(() => {
        const code = params.get('code')
        const state = params.get('state')

        if (code && state) {
            fetch('https://api.markerslab.app/auth/naver-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    code: code,
                    state: state,
                })
            })
                .then(res => res.json())
                .then((json) => {
                    LocalStorageUtility.set('user', json)
                    window.location.href = '/'
                })
        }
    }, [])

    return (
        <></>
    )
}
import { Button, Col, Modal, Row, Space, Spin, Typography } from "antd"
import { FunctionComponent, useEffect, useState } from "react"
import { CategoryModel } from "../../models/CategoryModel"
import { CategoryService } from "../../services/CategoryService"
import { Color } from "../../styles/Color"
import { unionBy } from "lodash-es"

interface IProps {
    open: boolean
    category: CategoryModel.ITrademarkCategoryFlattenModel
    secondaryCodes?: Array<string>
    onCompleted: (classification: CategoryModel.ITrademarkCategoryClassificationModel) => void
    onClosed: () => void
}

export const 연관상품류Modal: FunctionComponent<IProps> = (props) => {
    const { open, category, secondaryCodes, onCompleted, onClosed } = props

    const [isLoading, setLoading] = useState(true)
    const [categories, setCategories] = useState<Array<CategoryModel.ITrademarkCategoryV12Model>>([])
    const [selectedCategories, setSelectedCategories] = useState<Array<string>>([])

    const getV12 = async () => {
        setLoading(true)
        const response = await CategoryService.v12(category.codes.slice(1, category.codes.length))
        if (response.status === 200) {
            setCategories(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (open) {
            getV12()
        }
    }, [open])

    const Footer = () => {
        return (
            <Row justify={'center'} style={{ marginTop: 30 }}>
                <Col span={16}>
                    <Button
                        block
                        disabled={selectedCategories?.length <= 0}
                        className="primary"
                        onClick={() => {


                            onCompleted({
                                category: category.c4,
                                primary: category.codes[0],
                                // secondary: selectedCategories,
                                secondary: unionBy([...selectedCategories, ...(secondaryCodes ?? [])]),
                            })
                        }}
                    >
                        상표 검토
                    </Button>
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            width={'60%'}
            open={open}
            destroyOnClose={true}
            onCancel={() => { onClosed() }}
            footer={<Footer />}
        >
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Space direction="vertical">
                        <Typography.Text
                            style={{
                                fontSize: 24,
                            }}
                        >
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 24,
                                }}
                            >
                                {`회원님`}
                            </Typography.Text>
                            <Typography.Text
                                style={{
                                    fontSize: 24,
                                }}
                            >
                                {`의`}
                            </Typography.Text>
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 24,
                                }}
                            >
                                {` 상표와 연관된`}
                            </Typography.Text>
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontSize: 24,
                            }}
                        >
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 24,
                                }}
                            >
                                {`상품류`}
                            </Typography.Text>
                            <Typography.Text
                                style={{
                                    fontSize: 24,
                                }}
                            >
                                {`를 알려드려요.`}
                            </Typography.Text>
                        </Typography.Text>
                    </Space>
                </Col>
                <Col span={24}>
                    <Spin spinning={isLoading}>
                        {categories
                            .filter(c => !secondaryCodes?.includes(c.classificationCode))
                            .map((c) => {
                                const isActivated = selectedCategories.includes(c.classificationCode)

                                return (
                                    <Typography.Link
                                        key={c.classificationCode}
                                        onClick={() => {
                                            if (selectedCategories.includes(c.classificationCode)) {
                                                setSelectedCategories([...selectedCategories.filter(i => i !== c.classificationCode)])
                                            } else {
                                                setSelectedCategories([...selectedCategories, c.classificationCode])
                                            }
                                        }}
                                        style={{ marginBottom: 20, }}
                                    >
                                        <Row
                                            style={{
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                padding: '12px 10px',
                                                borderRadius: 8,
                                                backgroundColor: isActivated ? Color.Blue10 : Color.Gray10,
                                                borderColor: isActivated ? Color.Blue60 : Color.Gray50,
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Col span={2}>
                                                <Typography.Text
                                                    strong
                                                    style={{
                                                        fontSize: 18,
                                                        color: isActivated ? Color.Blue60 : Color.Gray80,
                                                    }}
                                                >
                                                    {`${c.classificationCode}류`}
                                                </Typography.Text>
                                            </Col>
                                            <Col span={22}>
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 18,
                                                        color: isActivated ? Color.Blue60 : Color.Gray80,
                                                    }}
                                                >
                                                    {c.description}
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </Typography.Link>
                                )
                            })}
                    </Spin>
                </Col>
                {!isLoading && categories.filter(c => !secondaryCodes?.includes(c.classificationCode)).length <= 0 && (
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Typography.Text
                            style={{
                                fontSize: 20,
                            }}
                        >
                            {'선택할 수 있는 추가 상품류가 없어요 :('}
                        </Typography.Text>
                    </Col>
                )}
            </Row>
        </Modal>
    )
}
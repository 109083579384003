
import { FunctionComponent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LocalStorageUtility from '../../../utils/LocalStorageUtility'
import { OrderModel } from '../../../models/OrderModel'

export const PaymentFail: FunctionComponent = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        console.log(id)

        if (!id) {
            window.location.href = '/'
        } else {
            alert('결제가 실패하였습니다.')
            const orderData = LocalStorageUtility.get(id)
            if (orderData) {
                const order = JSON.parse(orderData) as OrderModel.IOrderBackupModel
    
                navigate(`/payment/trademarks/${id}`, {
                    state: {
                        type: order.type,
                        name: order.koreanTrademarkName,
                        alphabet: order.englishTrademarkName,
                        description: order.description,
                        codes: order.codes,
                    }
                })
            }
        }
    }, [])

    return (
        <>
        </>
    )
}
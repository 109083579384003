import { Col, Row, Upload } from "antd"
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface"
import { ReactNode } from "react"

interface IProps {
    path: string
    children?: ReactNode
    onUploaded: (info: UploadChangeParam<UploadFile<any>>) => void
}

export const SingleImageUploadForm = (props: IProps) => {
    const { path, children, onUploaded } = props

    return (
        <Row>
            <Col span={24}>
                <Upload
                    action={path}
                    name="File"
                    multiple={false}
                    showUploadList={false}
                    onChange={(uploadedFile: UploadChangeParam<UploadFile<any>>) => {
                        onUploaded(uploadedFile)
                    }}
                    style={{ width: '100%' }}
                >
                    {children}
                </Upload>
            </Col>
        </Row>
    )
}
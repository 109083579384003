import { ReactNode, useEffect } from 'react';
import { Navigation } from './Navigation';
import { Footer } from './Footer';
import { Button } from 'antd';
import { AssetImage } from '../../assets/image/Index';
import { NavigationType } from '../../types/NavigationType';
import { MenuType } from '../../types/MenuType';
import { Utilities } from '../../utils/Utilities';

interface IProps {
    menu?: MenuType
    type?: NavigationType
    children: ReactNode
}

export const Container = (props: IProps) => {
    const {
        menu,
        type = NavigationType.Transparent,
        children,
    } = props

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className='container'>
                <Navigation menu={menu} type={type} />
                {children}
                <Footer />
            </div>
            <Mobile />
        </>
    )
}

const Mobile = () => {
    return (
        <div className='mobile-container'>
            <picture>
                <source srcSet={AssetImage.background.mobile} type="image/png" />
                <img alt='나혼자상표' style={{ width: '100%', marginBottom: 20 }} />
                <Button
                    block
                    type='primary'
                    style={{
                        height: 60,
                        fontSize: 18,
                        borderRadius: 0,
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                    onClick={() => {
                        if (Utilities.isAndroid()) {
                            window.location.href = 'https://play.google.com/store/apps/details?id=com.markerslab.atm'
                        } else {
                            window.location.href = 'https://apps.apple.com/kr/app/%EB%82%98%ED%98%BC%EC%9E%90%EC%83%81%ED%91%9C/id6449414426'
                        }
                    }}
                >
                    앱 다운로드
                </Button>
            </picture>
        </div>
    )
}
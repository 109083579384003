import AxiosContext from "../contexts/AxiosContext";

export const CategoryService = {
    search: async () => {
        try {
            const { data, status } = await AxiosContext.get(`/categories`);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
    flatten: async () => {
        try {
            const { data, status } = await AxiosContext.get(`/categories/flatten`);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
    v12: async (codes: Array<string>) => {
        try {
            const { data, status } = await AxiosContext.get(`/categories/nice/v12?Codes=${codes.join(',')}`);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
}
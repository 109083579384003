import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios"
import LocalStorageUtility from "../utils/LocalStorageUtility"
import { UserModel } from "../models/UserModel"
import { notification } from "antd"

const instance = axios.create({
    baseURL: 'https://api.markerslab.app/',
    timeout: 60000,
    withCredentials: false,
    responseType: "json",
    headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json charset:UTF-8'
    },
} as AxiosRequestConfig)

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers.Accept = 'application/json'
    const user = LocalStorageUtility.get('user')

    if (user) {
        const userModel = JSON.parse(user) as UserModel.IUserModel
        config.headers.Authorization = `Bearer ${userModel.accessToken}`
    }

    return config
})

instance.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response) {
        const { status, data, config } = error.response
        if (status === 400) {
            console.log("400", data, config)

            const error = (data as any)?.errors
            notification.error({
                message: error[Object.keys(error)[0]]
            },)
        }

        if (status === 401) {
            console.log("401", data, config)
            notification.error({
                message: '로그인 후 이용해주세요.',
                onClose: () => {
                    window.location.href = '/login'
                }
            },)
        }

        if (status === 403) {
            console.log("403", data, config)
        }

        if (status === 500) {
            console.log("500", data, config)
        }
    }

    return error.response
})

export default instance
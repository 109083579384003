import { Col, Row, Space, Spin, Typography } from "antd"
import { useEffect, useState } from "react"
import { Vector } from "../../assets/svg/Index"
import { OrderModel } from "../../models/OrderModel"
import { OrderService } from "../../services/OrderService"
import { Color } from "../../styles/Color"
import { 출원진행타입 } from "../../types/출원진행타입"
import DateUtility from "../../utils/DateUtility"
import LocalStorageUtility from "../../utils/LocalStorageUtility"

interface IProps {
    id: string,
    subId: string,
}

export const 상표출원진행현황상세 = (props: IProps) => {
    const { id, subId } = props

    const [isLoading, setLoading] = useState<boolean>(false)
    const [order, setOrder] = useState<OrderModel.IOrderModel>()

    const processes = [
        { type: 출원진행타입.출원진행.출원의뢰, name: '출원\n의뢰', },
        { type: 출원진행타입.출원진행.출원진행, name: '출원\n진행', },
        { type: 출원진행타입.출원진행.심사중, name: '심사중', },
        { type: 출원진행타입.출원진행.심사결과발표, name: '심사결과\n발표', },
        { type: 출원진행타입.출원진행.등록완료, name: '등록\n완료', },
    ]

    const getOrder = async () => {
        setLoading(true)
        const response = await OrderService.order(id, subId)
        if (response.status === 200) {
            setOrder(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        const userData = LocalStorageUtility.get('user')
        if (!userData) {
            alert('로그인 후 진행해주세요.')
            window.location.href = '/login'
        }

        getOrder()
    }, [])

    return (
        <Spin spinning={isLoading}>
            <Row justify={'center'} align={'middle'}>
                {order && (
                    <Col
                        span={24}
                        style={{
                            paddingTop: 40,
                            paddingBottom: 40,
                            backgroundColor: Color.Gray80,
                        }}
                    >
                        <Row
                            // style={{
                            //     width: '100%',
                            //     flexDirection: 'row',
                            //     justifyContent: 'space-between',
                            //     alignItems: 'flex-start',
                            //     marginTop: 35,
                            // }}
                            justify={"space-between"}
                            align={'middle'}
                        >
                            {processes.map((p, idx: number) => {
                                const isActivated = order.currentStep >= p.type
                                return (
                                    <Col
                                        key={p.type}
                                        span={4}
                                        style={{
                                            // width: 44,
                                            zIndex: 3,
                                            textAlign: 'center'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: 70,
                                                height: 70,
                                                backgroundColor: isActivated ? Color.Blue50 : Color.Gray30,
                                                padding: '19px 0px',
                                                margin: '0px auto',
                                                borderRadius: 35,
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Typography.Text
                                                style={{
                                                    textAlign: 'center',
                                                    fontSize: 20,
                                                    color: isActivated ? Color.Gray10 : Color.Gray80,
                                                }}
                                            >
                                                {idx + 1}
                                            </Typography.Text>
                                        </div>
                                        <Typography.Text
                                            style={{ textAlign: 'center', marginTop: 20, color: Color.Gray10, }}
                                        >
                                            {p.name}
                                        </Typography.Text>

                                    </Col>
                                )
                            })}
                            <div
                                style={{
                                    top: 72,
                                    left: '50%',
                                    right: 0,
                                    bottom: 0,
                                    position: 'absolute',
                                    transform: 'translateX(-50%)',
                                    width: '85%',
                                }}
                            >
                                <div
                                    style={{
                                        height: 2,
                                        alignSelf: 'center',
                                        backgroundColor: Color.Gray10,
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    top: 72,
                                    left: '50%',
                                    right: 0,
                                    bottom: 0,
                                    width: '85%',
                                    position: 'absolute',
                                    alignSelf: 'flex-start',
                                    transform: 'translateX(-50%)',
                                }}
                            >
                                <div
                                    style={{
                                        height: 2,
                                        // width: `${(20 * (order.currentStep - 1)) - 15}%`,
                                        width: `${(order.currentStep - 2) * 25}%`,
                                        backgroundColor: Color.Blue50,
                                    }}
                                />
                            </div>
                        </Row>
                    </Col>
                )}
                <Col span={24} style={{ textAlign: 'center' }}>
                    {order?.histories?.map((t, idx: number) => {
                        return (
                            <Row
                                key={idx}
                                style={{
                                    padding: '30px 0px',
                                    backgroundColor: Color.Gray20,
                                    opacity: idx === 0 ? 1 : 0.5
                                }}
                            >
                                <Col
                                    span={4}
                                    style={{ alignSelf: 'center', }}
                                >
                                    {(() => {
                                        switch (t.step) {
                                            case 출원진행타입.출원진행.출원의뢰:
                                                return (
                                                    <img
                                                        src={Vector.process.search}
                                                        alt={'icon'}
                                                    />
                                                )
                                            case 출원진행타입.출원진행.출원진행:
                                                return (
                                                    <img
                                                        src={Vector.process.check}
                                                        alt={'icon'}
                                                    />
                                                )
                                            case 출원진행타입.출원진행.심사중:
                                                return (
                                                    <img
                                                        src={Vector.process.text}
                                                        alt={'icon'}
                                                    />
                                                )
                                            case 출원진행타입.출원진행.심사결과발표:
                                                return (
                                                    <img
                                                        src={Vector.process.alarm}
                                                        alt={'icon'}
                                                    />
                                                )
                                            case 출원진행타입.출원진행.등록완료:
                                                return (
                                                    <img
                                                        src={Vector.process.bookmark}
                                                        alt={'icon'}
                                                    />
                                                )
                                        }
                                    })()}
                                </Col>
                                <Col
                                    span={20}
                                    style={{ textAlign: 'start' }}
                                >
                                    <Space direction="vertical">
                                        <Typography.Text
                                            strong
                                            style={{
                                                fontSize: 18,
                                            }}
                                        >
                                            {출원진행타입.displayName(t.step)}
                                        </Typography.Text>
                                        <Typography.Text
                                            style={{
                                                fontSize: 18,
                                            }}
                                        >
                                            {t.description}
                                        </Typography.Text>
                                        <Typography.Text
                                            style={{
                                                fontSize: 18,
                                                color: Color.Gray50,
                                            }}
                                        >
                                            {DateUtility.date_format(t.kstCreatedDate)}
                                        </Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
            </Row >
        </Spin>
    )
}
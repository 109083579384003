import { AxiosRequestConfig } from "axios";
import { DomainBindingModel } from "../bindings/DomainBindingModel";
import AxiosContext from "../contexts/AxiosContext";

export const DomainService = {
    search: async (model: DomainBindingModel.ISearchDomainBindingModel) => {
        try {
            const config: AxiosRequestConfig = {
                params: model
            }

            const { data, status } = await AxiosContext.get(`/domains`, config);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
}
// #region 로고
import WhiteLogo from './white-logo.svg'
import BlueLogo from './blue-logo.svg'
// #endregion

// #region 아이콘
import GraySearch from './icon/gray-search.svg'
import BlackCamera from './icon/black-camera.svg'
import GrayAlert from './icon/alert-gray.svg'
// #endregion

// #region check
import BlueCheck from './check/check-blue.svg'
import GrayCheck from './check/check-gray.svg'
// #endregion

import Process_Alarm_Blue from './process/alarm-blue.svg'
import Process_Bookmark_Blue from './process/bookmark-blue.svg'
import Process_Check_Blue from './process/check-blue.svg'
import Process_Search_Blue from './process/search-blue.svg'
import Process_Text_Blue from './process/text-blue.svg'

export const Vector = {
    logo: {
        white: WhiteLogo,
        blue: BlueLogo,
    },
    icon: {
        search: {
            gray: GraySearch,
        },
        camera: {
            black: BlackCamera,
        },
        alert: {
            gray: GrayAlert,
        }
    },
    check: {
        blue: BlueCheck,
        gray: GrayCheck,
    },
    process: {
        alarm: Process_Alarm_Blue,
        bookmark: Process_Bookmark_Blue,
        check: Process_Check_Blue,
        search: Process_Search_Blue,
        text: Process_Text_Blue,
    }
}
export namespace 출원진행타입 {
    export enum 출원진행 {
        출원의뢰 = 2,
        출원진행 = 3,
        심사중 = 4,
        심사결과발표 = 5,
        등록완료 = 6,
    }

    export const displayName = (type: 출원진행) => {
        switch (type) {
            case 출원진행.출원의뢰:
                return '출원 의뢰'
            case 출원진행.출원진행:
                return '출원 진행'
            case 출원진행.심사중:
                return '심사중'
            case 출원진행.심사결과발표:
                return '심사결과 발표'
            default:
                return '등록 완료'
        }
    }
}
export namespace 상표검색타입 {
    export enum 타입 {
        All = 1,
        상표 = 2,
        출원인 = 3,
    }

    export const displayName = (type: 상표검색타입.타입) => {
        switch (type) {
            case 타입.All:
                return 'All'
            case 타입.상표:
                return '상표'
            default:
                return '출원인'
        }
    }
}
import { AxiosRequestConfig } from "axios";
import AxiosContext from "../contexts/AxiosContext";
import { OrderBindingModel } from "../bindings/OrderBindingModel";

export const OrderService = {
    create: async (model: OrderBindingModel.ICreateOrderBindingModel) => {
        try {
            const { data, status } = await AxiosContext.post(`/orders`, model);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
    order: async (id: string, subId: string) => {
        try {
            const config: AxiosRequestConfig = {
                params: { SubIds: subId } 
            }

            const { data, status } = await AxiosContext.get(`/orders/${id}`, config);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
    search: async (page: number = 0, pageSize: number = 50,) => {
        try {
            const config: AxiosRequestConfig = {
                params: { PageIndex: page, PageSize: pageSize } 
            }

            const { data, status } = await AxiosContext.get(`/orders`, config);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
}

import { Col, Row } from 'antd'
import { FunctionComponent, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import "pdfjs-dist/build/pdf.worker.entry"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString()

export const 특허출원방법PDF: FunctionComponent = () => {
    const [numPages, setNumPages] = useState<number>()

    return (
        <Row>
            <Col span={24}>
                <Document
                    file={'/license.pdf'}
                    onLoadSuccess={({ numPages }: { numPages: number }) => {
                        setNumPages(numPages)
                    }}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={window.innerWidth}
                        />
                    ))}
                </Document>
            </Col>
        </Row>
    )
}
import { CheckBindingModel } from "../bindings/CheckBindingModel";
import AxiosContext from "../contexts/AxiosContext";

export const CheckService = {
    ratio: async (model: CheckBindingModel.ICheckRatioBindingModel) => {
        try {
            const { data, status } = await AxiosContext.post(`/check/v2`, model);
            return { data: data, status: status }
        } catch (error) {
            return { data: null, error: error }
        }
    },
}
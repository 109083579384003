export enum Color {
    Transparent = 'transparent',

    White = '#FFFFFF',
    MainBlue = '#256AFF',
    MainGray = '#363A3D',
    SubGreen = '#35D48D',
    SubRed = '#FF5D5D',

    Kakao = '#FEE500',
    Naver = '#03C75A',
    Apple = '#000000',

    Blue10 = '#F3FCFF',
    Blue20 = '#E0F6FD',
    Blue30 = '#9DE4FC',
    Blue40 = '#67C3E3',
    Blue50 = '#36AFD9',
    Blue60 = '#289EC7',
    Blue70 = '#158BB2',
    Blue80 = '#0E6B8A',
    Blue90 = '#0B536B',
    Blue100 = '#07394A',

    Gray10 = '#FDFEFF',
    Gray20 = '#F2F3F4',
    Gray30 = '#E1E4E6',
    Gray40 = '#CED3D6',
    Gray50 = '#A9AFB3',
    Gray60 = '#878D91',
    Gray70 = '#5E6367',
    Gray80 = '#363A3D',
    Gray90 = '#282C2D',
    Gray100 = '#151717',

    Orange10 = '#FFF3EB',
    Orange20 = '#FFE4D2',
    Orange30 = '#FFA569',
    Orange40 = '#FF8C40',
    Orange50 = '#FF7A22',
    Orange60 = '#EB650D',
    Orange70 = '#D65A08',
    Orange80 = '#C04E02',
    Orange90 = '#863602',
    Orange100 = '#6B2A00',
}